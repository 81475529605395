@import "../../theme.scss";

body {
    background-color: #f7f7f7 !important;
}

.card {
    background: $white-color;
    box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
    border-radius: 30px !important;
    border: none !important;
}

.relative {
    position: relative;
    & .card {
        position: absolute;
        width: 33%;
        height: 100%;
        border-radius: 25px !important;
        top: 0;
        right: 0;
    }
}

a:hover span {
    & .map-animation {
        animation: bounce;
        animation-duration: 1s;
    }
    & .phone-animation {
        animation: swing;
        animation-duration: 1s;
    }
    & .mail-animation {
        animation: lightSpeedInLeft;
        animation-duration: 1s;
    }
}

@media only screen and (max-width: 768px) {
    .relative {
        position: relative;
        & .card {
            position: relative;
            width: 100%;
        }
    }

    .container + iframe {
        padding: 0 !important;
    }
}

.input-box .icon-btnl.textarea {
    top: 20% !important;
}
