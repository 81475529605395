.w100 {
    width: 100% !important;
}

.w50p {
    width: 50px !important;
}

.w10p {
    width: 10px !important;
}

.w20p {
    width: 20px !important;
}

.w30p {
    width: 30px !important;
}

.w100p {
    width: 100px !important;
}
.h100vh{
    height: 100vh;
}
.h100 {
    height: 100% !important;
}

.h50p {
    height: 50px !important;
}

.h10p {
    height: 10px !important;
}

.h20p {
    height: 20px !important;
}

.h30p {
    height: 30px !important;
}

.h100p {
    height: 100px !important;
}

.h200p {
    height: 200px !important;
}

.h350p {
    height: 350px !important;
}

.w200p {
    width: 200px !important;
}

.h700p {
    height: 700px !important;
}

.min-h230p {
    min-height: 230px !important;
}

.min-h95p {
    min-height: 95px !important;
}

.max-h100 {
    max-height: 100% !important;
}

@media only screen and (max-width: 768px) {
    .h700p {
        height: 300px !important;
    }

    .min-h230p {
        min-height: 130px !important;
    }

    .h100p {
        height: 50px !important;
    }
}
