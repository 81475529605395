@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

$font-stack: "Poppins", sans-serif;
$font-second:'Roboto Mono', monospace;
$bg-color: #f7f7f7;
$primary-color: #ff9819;
$secondary-color: #a2ccec;
$grey-color: #e6e7e7;
$light-grey-color: #f1f1f1;
$dark-color: #2d2d2d;
$white-color: #ffffff;
$brown-color: #5c302a;
$dark-blue-color: #266a79;
$width-auto: auto;
$red-alert: #ff0f0f;
$grey-disabled: #dddddd;

$font-black-color: #333333;

// timeline color

$timeline-red-color: #e61257;
$timeline-orange-color: #ed7d1f;
$timeline-green-color: #9fc72f;
$timeline-blue-color: #31b4d2;
$timeline-purple-color: #983f80;
$timeline-dark-blue-color: #00194f;

//font-size
$header: 64px;
$head-title: 36px;
$subtitle: 24px;
$smallest: 18px;
