@import "../../../theme.scss";

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  h3 {
    font-weight: 600;
  }
  svg {
    font-size: 24px;
    margin-right: 10px;
    font-weight: 700;
    margin-bottom: 8px;
  }
}
.learn-col {
  margin: 10px 0;
  position: relative;
}
.learn-card {
  border-radius: 20px !important;
  cursor: pointer;
  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 30px;
    object-fit: contain;
    height: 200px;
    max-height: 200px;
    &.no-padding {
      padding: 0px !important;
    }
  }
  .card-body {
    position: relative;
    // height: 100%;
    height: 145px;
    .card-body-top {
      font-size: 0.75rem;
      opacity: 0.7;
    }
    .card-body-title {
      margin-bottom: 10px;
      font-size: 0.85rem !important;
      font-weight: 600;
      height: 41px;
    }
    .card-body-desc {
      margin-bottom: 10px;
      font-size: 0.75rem !important;
    }
    .card-body-bottom {
      background-color: transparent;
      border-top: none;
      display: flex;
      justify-content: flex-end;
      padding-right: 0px !important;
      padding-left: 0px !important;
      * {
        margin-right: 1px;
        opacity: 0.7;
      }
      .user {
        margin: 0px;
        cursor: pointer;
        font-size: 0.75rem;
        transition: 0.2s;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  &:hover {
    box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.08);
  }
}
.learn-col:nth-of-type(3n + 1) .learn-card .learn-card-image {
  background-color: #f9dbbd;
}
.learn-col:nth-of-type(3n + 2) .learn-card .learn-card-image {
  background-color: #e8fccf;
}
.learn-col:nth-of-type(3n + 3) .learn-card .learn-card-image {
  background-color: #afc2d5;
}
.hr {
  border-bottom: 1px solid $light-grey-color;
}
.category-slide {
  .scrolling-wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    gap: 0 10px;
    padding: 20px 0;
    scroll-behavior: smooth;
    width: 100%;
    .react-horizontal-scrolling-menu--item {
      flex: 0 0 max-content;

    }
    .btn-item {
      width: 100%;
      border: 1px solid $grey-color;
      border-radius: 5px;
      padding: 10px 15px;
      background-color: $white-color;
      box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.03);
    }
    .active {
      background-color: $primary-color;
      color: $white-color;
      border-color: #f9dbbd;
    }
  }
}
