.bg-primary-color {
    background-color: $primary-color !important;
}

.bg-secondary-color {
    background-color: $secondary-color !important;
}

.bg-grey-color {
    background-color: $grey-color !important;
}

.no-background {
    background-color: transparent !important;
    box-shadow: none !important;
}

.timeline-bg-red-color {
    background-color: $timeline-red-color;
}

.timeline-bg-orange-color {
    background-color: $timeline-orange-color;
}

.timeline-bg-green-color {
    background-color: $timeline-green-color;
}

.timeline-bg-blue-color {
    background-color: $timeline-blue-color;
}

.timeline-bg-purple-color {
    background-color: $timeline-purple-color;
}

.timeline-bg-dark-blue-color {
    background-color: $timeline-dark-blue-color;
}
