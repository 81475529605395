.input-box {
    background-color: $light-grey-color;
    box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
    border-radius: 10px;
    position: relative;
    & .icon-btnl {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        transition: 0.3s;
    }
}

.input-form {
    margin: 0;
    padding: 25px 15px 5px 55px;
    width: 100%;
    border: none;
    background-color: transparent;
    &:focus {
        outline: none;
    }
    &:focus ~ .icon-btnl {
        color: $primary-color !important;
    }
    &.input-textarea {
        padding-top: 0 !important;
        margin-top: 25px !important;
    }
}

.input-label {
    padding-left: 55px;
    position: absolute;
    left: 0;
    transition: 0.3s;
}

.input-check-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
    & input:checked ~ .checkmark {
        background-color: $primary-color;
    }
    & input:checked ~ .checkmark:after {
        display: block;
    }
    & .checkmark:after {
        left: 7px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
    }
}

.checkmark {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 2px;
    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}

.search-box {
    background-color: $light-grey-color;
    border: 1px solid $light-grey-color;
    box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
    border-radius: 10px;
    position: relative;
    & .icon-btnl {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        transition: 0.3s;
    }
}

.search-form {
    margin: 0;
    padding: 10px 15px 5px 55px;
    width: 100%;
    border: none;
    background-color: transparent;
    &:focus {
        outline: none;
    }
    &:focus ~ .icon-btnl {
        color: $primary-color !important;
    }
    &.input-textarea {
        padding-top: 0 !important;
        margin-top: 25px !important;
    }
}

.checkmark {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 2px;
    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}
