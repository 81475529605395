@import "../../theme.scss";

body {
    background-color: #f7f7f7 !important;
}
.card {
    background: $white-color;
    box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
    border-radius: 30px !important;
    border: none !important;
    .text-date{
        display: flex;
        align-items: center;
        gap: 0 5px;
        opacity: .6;
        font-size: .75rem;
        margin:10px 0;
        font-weight: 600;
    }
}
.relative img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.text-elipsis-single{
    font-size: 1.25rem;
}
.head-title {
    h1{
        font-size: 2em;
        line-height: 1.25em;
    }
    span {
        opacity: 0.6;
    }
    .col{
        margin: auto;
    }
    .activity-status{
        color: $primary-color;
        font-weight: 600;
    }
    .activity-img{
        position:relative;
        #left{        
            position:absolute;
            background:$primary-color;
            width: 300px;
            height: 180px;
            top: 0;
            left: 0;
            z-index: -1;
        }
        #right{ 
            position:absolute;
            background:$brown-color;
            width: 300px;
            height: 180px;
            bottom: 0;
            right: 0;
            z-index: -1;
        }
        #center {
            width: 100%;
            padding: 20px;
            object-fit: cover;
            border-radius: 30px;
            filter: drop-shadow(4px 8px 24px rgba(18, 18, 18, 0.05));
        }
    }
}
.body-activity {
    background: url("../../assets/images/logobig.png") no-repeat;
    background-position: calc(100% + 20%) calc(100% - 50%);
    margin-top: 30px;
    margin-bottom: 50px;
    .content{
        padding: 0 30px;
    }
}
aside{
    .side-title{
        h5{
            font-weight: 600;
        }
    }
    .side-body{
        ul{
            padding: 0;
            list-style: none;
        }
        li{
            display: flex;
            border-bottom: $grey-color 1px solid;
            padding: 10px 0;
            cursor: pointer;
            img{
                width: 100%;
                object-fit: contain;
                border-radius: 5px;
            }
        }
    }
    @media only screen and (max-width: 750px) {
        margin-top: 30px;
        li a{
            span{
                height: 6rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: justify;
            }
        }
    }
}