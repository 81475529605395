.img-fit-cover {
    object-fit: cover !important;
}

.img-content {
    border-radius: 5px;
    box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
}

.img-content {
    width: 100%;
    &.top-content {
        height: 350px;
    }
    &.btm-content {
        height: 250px;
    }
    &.height100-m {
        height: 100%;
    }
}

.img-center-parent {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 230px;
    transform: translate(-50%, -50%);
}

.img-border-solid1p {
    border: 1px solid;
    &.white {
        border-color: #fff;
    }
}

.img-circle-container {
    position: relative;
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    border-radius: 50%;
    margin: auto;
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.img-size-100 {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .img-content {
        &.top-content {
            height: 200px;
        }
        &.btm-content {
            height: 100%;
        }
        &.height100-m {
            height: 100px;
        }
    }
}

.image-circle {
    border-radius: 50%;
}
