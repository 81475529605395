.blank {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 50%;
        margin: 0;
    }
    .item {
        margin: 10px 0;
        h4 {
            font-size: 36px !important;
            color: $brown-color;
        }
        p {
            font-size: 24px;
        }
    }
}

.line-lr {
    position: relative;
    color: $grey-color;
    &:before {
        content: "";
        display: block;
        width: 40%;
        height: 1px;
        background: $grey-color;
        left: 0;
        top: 50%;
        position: absolute;
    }
    &:after {
        content: "";
        display: block;
        width: 40%;
        height: 1px;
        background: $grey-color;
        right: 0;
        top: 50%;
        position: absolute;
    }
}

.icon-top {
    display: block;
    padding-bottom: 20px;
}

.dot-bottom {
    position: absolute;
    margin: auto;
    width: 5px;
    height: 5px;
    background: transparent;
    border-radius: 50%;
    transition: 0.5s;
    left: 50%;
    bottom: 0;
    transition: 0.5s;
}

.tag-label {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    display: inline-block;
    &.primary {
        background-color: $primary-color;
        color: $white-color;
    }
    &.secondary {
        background-color: $secondary-color;
        color: $white-color;
    }
    &.red {
        background-color: $timeline-red-color;
        color: $white-color;
    }
}

.card {
    background-color: $white-color;
    margin: 10px 0;
    overflow: hidden;
    &.background-none {
        background-color: transparent !important;
    }
    &.box-shadow-none {
        box-shadow: none !important;
    }
    &.border-r30 {
        border-radius: 30px !important;
    }
    &.border-r10 {
        border-radius: 10px !important;
    }
    &.border-s1 {
        border: 1px solid #e6e7e7 !important;
    }
}
.card1 {
    background-color: #f9dbbd !important;
}
.card2 {
    background-color: #cdcfca !important;
}
.card3 {
    background-color: #afc2d5 !important;
}
.card4 {
    background-color: #96c299 !important;
}
.card5 {
    background-color: #c5a9c5 !important;
}
.clickable {
    cursor: pointer;
}
.item-slide{
    display: flex;
  width: 100%;
  overflow: hidden;
  gap: 0 10px;
  padding: 20px 0;
  scroll-behavior: smooth;
}