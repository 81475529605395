@import "../../theme.scss";

.brand-dashboard {
  width: 20%;
  @media only screen and (max-width: 750px) {
    margin: auto;
    width: auto;
  }
  img {
    float: left;
    height: 64px;
    width: auto;

    @media only screen and (max-width: 750px) {
      width: auto;
    }
  }
}

.main {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 30px;
  width: 80%;
  height: 100%;
  padding: 0px 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* box-shadow: 6px 6px 14px 1px rgba(0, 0, 0, 0.2), */
  /* -3px -3px 13px -10px rgba(255, 255, 255, 0.4); */
  @media only screen and (max-width: 992px) {
    width: 100%;
    left: 0;
    padding: 10px !important;
  }
  .card {
    border-radius: 20px !important;
  }
}
.hidden{
  display: none;
}
#toggle {
  background: url("../../assets/images/menu.png") no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.private {
  transform: scaleX(-1);
}
.closebtn {
  visibility: visible;
  display: none;
}

.openbtn {
  visibility: visible;
  display: none;
}
@media only screen and (max-width: 992px) {
  .closebtn {
    position: relative;
    background: none;
    top: 0;
    font-size: 36px;
    display: block;
    border: none;
  }

  .openbtn {
    display: block;
    font-size: 24px;
    cursor: pointer;
    padding: 0 15px;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  .openbtn:hover {
    color: $secondary-color;
  }
}

.sideNav-wrap {
  height: 100%;
  width: 20%;
  position: absolute;
  padding: 10px;
}

.sidenav-dash {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  // position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  // margin: 10px 10px;
  background-color: $white-color;
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid $grey-color;
  border-radius: 20px;

  @media only screen and (max-width: 992px) {
    border-radius: 0;
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    margin: 0;
    background-color: $light-grey-color; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s;
  }
}

.show-menu {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.flex-column {
  flex: 1;
  margin: 30px 0;
  * {
    margin-left: 10px;
  }
  .link {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin: 5px 0;
    * {
      margin-right: 10px;
    }
    &:hover {
      color: $secondary-color !important;
      background: #eefbff;
      // background-color: $secondary-color !important;
    }
  }
  .navbar__link--active {
    position: relative;
    border-right: solid 5px $secondary-color;
    color: $secondary-color !important;
  }
}
.logoutBtn {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  color: $white-color !important;
  background-color: $secondary-color;
  margin: 0 10px;
  cursor: pointer;
  * {
    margin-right: 10px;
  }
  &:hover {
    background: #eefbff !important;
    color: $secondary-color !important;
    box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.05);
  }
}
.avatar-box {
  display: flex;
  background-color: $secondary-color;
  border-radius: 10px;
  padding: 10px 5px;
  justify-content: space;
  align-items: center;
  margin: 0 10px;
  color: $white-color;
  cursor: pointer;
  * {
    margin-left: 5px;
    margin-right: 5px;
  }
  .avatar-img img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  .avatar-id {
    flex: 1 0;
    h5 {
      font-size: 1.2rem;
      line-height: 0.5;
    }
    span {
      font-size: 0.7rem;
      opacity: 0.7;
    }
  }
  &:hover {
    background-color: $primary-color;
  }
}
.avatar-head {
  display: flex;
  border-radius: 10px;
  padding: 10px 5px;
  justify-content: space;
  align-items: center;
  margin: 0 0px;
  color: $font-black-color !important;
  * {
    margin-left: 5px;
    margin-right: 5px;
  }
  .avatar-img img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  .avatar-id {
    flex: 1 0;
    h5 {
      font-size: 1.2rem;
      line-height: 0.5;
    }
    span {
      font-size: 1rem;
      opacity: 0.7;
    }
  }
}
.card-form {
  background-color: $white-color;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.05);
}
.top-header {
  display: flex;
  align-items: center;
  gap: 0 10px;
  
}
.side-block {
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid $light-grey-color;
  .title-block {
    display: flex;
    align-items: center;
    gap: 0 5px;
    color: $secondary-color;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
}
.react-horizontal-scrolling-menu--scroll-container{
  scroll-behavior: smooth;
}