@import "../../theme.scss";

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes slide-up {
    0% {
        transform: TranslateY(250px);
    }
    100% {
        transform: TranslateY(0px);
    }
}

.carousel {
    position: relative;
    min-height: 400px;
    .overlay {
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: rgba(#7dd1e4, 0.5);
        z-index: 1;
    }
    .inner {
        overflow: hidden;
        text-align: center;
    }
    h1 {
        letter-spacing: 1.05rem;
        text-transform: uppercase;
        font-size: 3.5em;
        animation: slide-up 1s ease;
    }
    p {
        font-size: 1.2em;
        padding: 0px;
        animation: slide-up 1.5s ease;
    }
    button {
        animation: slide-up 2s ease;
    }
    .carousel-item {
        @media only screen and (max-width: 750px) {
            height: 400px;
        }
    }
    .carousel-caption {
        top: 40%;
        bottom: 50%;
        z-index: 2;
        @media only screen and (max-width: 750px) {
            top: 30%;
            right: 0;
            left: 0;
        }
    }
    img {
        position: relative;
        object-fit: cover;
        min-height: 400px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 9999;
        }
    }
    .carousel-control-prev,
    .carousel-control-next {
        z-index: 2;
    }
}

.section-1 {
    background-color: $light-grey-color;
    .item-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        height: 350px;
        @media only screen and (max-width: 750px) {
            display: block;
            height: auto;
        }
        .item {
            padding: 30px 10px;
            text-align: center;
            flex: 0 1 calc(25% - 1em);
            height: 100%;
            transition: 0.3s;
            h5 {
                font-size: 1.25rem;
            }
            .bg-icon {
                background-color: transparent;
                color: $primary-color;
            }
            * {
                margin: 10px 0;
            }
            &:hover {
                background: $secondary-color;
                transform: scale(1.05);
                -webkit-box-shadow: -10px 0px 13px -7px rgba(162, 204, 236, 0.7),
                    10px 0px 13px -7px rgba(162, 204, 236, 0.7),
                    5px 5px 15px 5px rgba(162, 204, 236, 0);
                box-shadow: -10px 0px 13px -7px rgba(162, 204, 236, 0.7),
                    10px 0px 13px -7px rgba(162, 204, 236, 0.7),
                    5px 5px 15px 5px rgba(162, 204, 236, 0);
                opacity: 1;
            }
        }
    }
    a {
        text-decoration: none;
        float: right;
        color: $brown-color !important;
    }
    img {
        filter: grayscale(50%);
        box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);

        @media only screen and (max-width: 750px) {
            height: 100% !important;
            object-fit: contain !important;
        }
    }
}
.item {
    * {
        padding: 5px 0;
    }
    img {
        border-radius: 10px;
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    p {
        color: $font-black-color;
        opacity: 0.7;
    }
}
.bg-icon {
    padding: 10px 5px;
    border-radius: 5px;
    background: $primary-color;
    color: $white-color;
}

.section-2 {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    .col {
        margin: auto;
    }
    .img-bg {
        overflow: hidden;
        z-index: -1;
        position: absolute;
        top: 0;
        background-color: $dark-blue-color;
        bottom: 0;
        width: calc((75vw - 12px) - ((100vw - 1200px) * 0.25));
        max-width: 75%;
        left: 0;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            opacity: 0.3;
        }
        @media only screen and (max-width: 750px) {
            max-width: 100%;
        }
    }
    .item-main {
        h2 {
            font-size: 3rem;
            font-size: 3rem;
            line-height: 3.5rem;
            // margin-top: 3.5rem;
            font-weight: 700;
            color: $white-color;
        }
    }
    .item-aside {
        background-color: $bg-color;
        padding: 1.75rem 3.5rem;
        .item {
            margin: 20px 0;
            border-top: 1px solid #ddd;
            &:before {
                display: block;
                left: 0;
                content: "";
                height: 8px;
                background-color: #7dd1e4;
                width: 20%;
                top: 0;
            }
            .countUp {
                font-weight: 600;
            }
            span {
                font-weight: 600;
            }
            h4 {
                font-size: 2.5rem;
            }
        }
    }
}

.section-3 {
    background: url("../../assets/images/logobig.png") no-repeat;
    background-position: calc(100% + 20%) calc(100% - 50%);
    margin-top: 50px;
    margin-bottom: 50px;
    & .vertical-timeline-element-content-arrow {
        border: 20px solid transparent;
        border-right: 20px solid #fff;
        top: 7px !important;
    }

    & .vertical-timeline--two-columns {
        & .vertical-timeline-element-content {
            & .vertical-timeline-element-date {
                top: 0;
                font-size: 30px;
                padding-top: 0px !important;
                color: $font-black-color;
            }
        }
    }
}

.section-4 {
    position: relative;
    background: url("../../assets/images/section4.png") no-repeat;
    background-size: cover;
    margin: 50px 0;
    height: 100%;
    padding: 50px 0;
    h2 {
        text-align: center;
        margin: 10px auto;
    }
    .scrolling-wrapper {
        .card-box {
            height: 100%;
            width: 300px;
            margin-right: 30px;
            position: relative;
            display: table;
            padding: 30px 0;
            flex: 0 0 auto;
            @media only screen and (max-width: 768px) {
                width: 250px;
                min-height: 470px !important;
            }
            .card-icon {
                position: absolute;
                top: 0;
                left: 20px;
                z-index: 2;
                .bg-icon {
                    padding: 5px 5px 8px;
                    margin: auto;
                }
            }
            .card {
                max-width: 300px;
                display: table-cell;
                padding: 20px 15px 20px 30px;
                border-radius: 5px !important;
            }
            * {
                margin: 10px 0;
                white-space: normal;
            }
            h5 {
                font-weight: 500;
                color: $primary-color;
                font-size: 2.5rem;
            }
            .card-text {
                color: $font-black-color;
                border-left: 2px solid $grey-color;
                opacity: 0.7;
                padding-left: 10px;
            }
        }
        

        button {
            background: transparent;
            // height: 50px;
            margin: auto;
            border: none;
            font-size: 30px;
            padding: 0;
            color: $primary-color;
        }
    }
    @media only screen and (max-width: 768px) {
        .p-absolute {
            position: absolute !important;
        }
        button {
            display: none;
        }
    }
}
.react-horizontal-scrolling-menu--wrapper{
    width: 100%;
}
.react-horizontal-scrolling-menu--scroll-container {
    scrollbar-width: none;
    gap: 0 5px;
    &::-webkit-scrollbar {
        display: none !important;
    }
    .react-horizontal-scrolling-menu--item {
        flex-basis: 30%;
        box-sizing: border-box;
    }
}
.nav-item {
    border-right: 2px $primary-color solid !important;
    &:last-child {
        border-right: none !important;
    }
}

.section-6 {
    padding: 50px 0;
    height: 100%;
    .card {
        padding: 20px 50px;
        width: 100%;
        min-height: 230px;
        display: flex;
        align-items: center;
        padding: 0 5px;
        margin: 10px 0;
        &.min-h230p {
            @media only screen and (max-width: 750px) {
                min-height: 230px !important;
            }
        }
        & .card-title {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            color: #fff;
            font-weight: 400;
            letter-spacing: 2px;
        }
    }
}

.section-5 {
    padding: 50px 0;
    height: 100%;
    .tab-content {
        position: relative;
        .card {
            padding: 20px 50px;
            img {
                width: 100%;
                height: 12vw;
                object-fit: contain;
                margin: auto;
                @media only screen and (max-width: 750px) {
                    height: 20vw;
                }
            }
        }
    }
    .nav-tabs {
        border: none;
        display: flex;
        width: 55%;
        margin: auto;
        flex-wrap: nowrap;
        overflow-x: auto;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }
        @media only screen and (max-width: 750px) {
            width: 100%;
        }
    }
    .nav-link {
        border: none !important;
        background: none !important;
        border-right: none;
        border-radius: 0 !important;
        color: $dark-color;
        flex: 0 0 auto;
        &:hover {
            color: $primary-color !important;
        }
    }
    .nav-link.active {
        color: $primary-color !important;
    }
    .card {
        box-shadow: none !important;
        filter: grayscale(80%);
        width: 100%;
        min-height: 230px;
        display: flex;
        align-items: center;
        padding: 0 5px;
        margin: 10px 0;
        transition: 1s;
        * {
            margin: 10px 0;
            white-space: normal;
        }
        p {
            color: $font-black-color;
        }
        img {
            width: 100%;
        }
        svg {
            filter: $primary-color;
            margin: auto;
        }
        &:hover {
            filter: grayscale(0%);
            & img {
                animation: pulse;
                animation-duration: 2s;
            }
        }
    }
}
.section-7{
    position: relative;
    .card {
        padding: 20px 50px;
        width: 100%;
        min-height: 230px;
        display: flex;
        align-items: center;
        padding: 0 5px;
        margin: 10px 0;
        transition: 0.3s;
        &.min-h230p {
            @media only screen and (max-width: 750px) {
                min-height: 230px !important;
            }
        }
        & .card-title {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            color: #fff;
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: capitalize;
            font-size: 18px;
            padding: 5px 15px;
            &.big{
                font-size: 26px;
            }
        }
        .overlay{
            display: none;
        }
        &:hover{
            .overlay{
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            }
        }
    }
}