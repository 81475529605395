.btn-group {
  display: flex;
  gap: 0px 5px;
}
.btn-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: none;
  background: $primary-color;
  border-radius: 50%;
  color: $white-color;
}
.btn-primary {
  background: $primary-color !important;
  color: $white-color !important;
  border: none;
  padding: 0.75em 1.5em;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
  font-weight: 600 !important;
  font-size: 1rem;
  border-color: transparent !important;
  &:hover {
    background: $secondary-color !important;
    color: $brown-color !important;
  }
  &.clear {
    background-color: $white-color !important;
    color: $brown-color !important;
    &:hover {
      background: $secondary-color !important;
      color: $brown-color !important;
    }
  }
}
.btn-secondary {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 0.5rem 1rem;
  width: 100%;
  gap: 0 5px;
  border: none;
  border-radius: 5px;
  background: $secondary-color !important;
  color: $white-color !important;
  font-size: 1.15rem;
  font-weight: 600 !important;
  &:hover {
    background: $light-grey-color !important;
    color: $brown-color !important;
  }
  &.clear {
    background-color: $white-color !important;
    color: $brown-color !important;
    &:hover {
      background: $light-grey-color !important;
      color: $brown-color !important;
    }
  }
}
.dark-button {
  background: $dark-color;
  color: #f7f7f7;
  border: none;
  padding: 0.75em 1.5em;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: $bg-color;
    color: $dark-color;
  }
}

.btn-clear {
  text-decoration: none;
  background-color: transparent !important;
  color: $dark-color !important;
  border: 1px solid rgba(18, 18, 18, 0.1) !important;
  transition: 0.3s;
  &:hover {
    background: $grey-color !important;
  }
}
.btn-clear-all {
  display: flex;
  align-items: center;
  gap: 0;
  text-decoration: none;
  background-color: transparent !important;
  color: $dark-color !important;
  transition: 0.3s;
  font-size: 1.1 !important;
  font-weight: 600 !important;
  padding: 0.2rem 0.2rem !important;
  margin: 5px 0;
  &:hover {
    color: $primary-color !important;
  }
}

.underline {
  text-decoration: underline;
}

.btn-primary-lg {
  text-decoration: none;
  color: $white-color !important;
  background-color: $primary-color;
  padding: 15px 30px;
  margin: 0.75rem;
  display: block;
  box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
  border-radius: 10px;
  transition: 0.3s;
  line-height: 27px;
  cursor: pointer;
  &:hover {
    background-color: $secondary-color;
    color: $brown-color !important;
  }
}

.btn-white {
  text-decoration: none;
  color: $dark-color;
  background-color: $white-color;
  padding: 15px 30px;
  margin: 0.75rem;
  display: block;
  box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
  border-radius: 10px;
  transition: 0.3s;
  font-size: 1rem;
  line-height: 27px;
  font-weight: 600 !important;
  &:hover {
    background-color: $primary-color;
    color: $white-color !important;
  }
  &.no-border {
    border: none !important;
  }
}

.icon-btn {
  margin-right: 20px;
  font-size: 25px;
  line-height: 27px;
}
.btn-transform {
  display: inline-block;
  padding: 0.5rem 1.2rem;
  background-color: $secondary-color;
  border: none;
  box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
  color: #fff;
  border-radius: 5px;
  vertical-align: middle;
  transition: width 0.5s;
  .btn-icon {
    margin: 0 3px;
  }
  .btn-text {
    max-width: 0;
    display: inline-block;
    -webkit-transition: color 0.25s 1.5s, max-width 2s;
    transition: color 0.25s 1.5s, max-width 2s;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover .btn-text {
    max-width: 200px;
  }
}
.btn-vote {
  display: flex;
  align-items: center;
  border: none !important;
  font-size: 12px;
  gap: 0 5px;
  background: transparent;
  &:hover {
    color: #7d95ff;
  }
}

@media only screen and (max-width: 768px) {
  .btn-white {
    padding: 15px 10px !important;
  }
}
.btn-edit {
  color: #7d95ff !important;
  cursor: pointer;
  font-size: 16px;
}

.btn-delete {
  color: #ff8686 !important;
  cursor: pointer;
  font-size: 16px;
}
.group-section {
  display: flex;
  justify-content: center;
  gap: 0 10px;
  button {
    padding: 10px 15px;
    border: none;
    font-size: 24px;
    border-radius: 5px;
  }
  button:nth-of-type(1) {
    background: rgba(97, 165, 255, 0.2);
    color: rgba(97, 165, 255, 1);
    border: 1px solid rgba(97, 165, 255, 0.2);
  }
  button:nth-of-type(2) {
    background: rgba(255, 186, 97, 0.2);
    color: rgba(255, 186, 97, 1);
    border: 1px solid rgba(255, 186, 97, 0.2);
  }
  button:nth-of-type(3) {
    background: rgba(255, 97, 189, 0.2);
    color: rgba(255, 97, 189, 1);
    border: 1px solid rgba(255, 97, 189, 0.2);
  }
}

.no-border {
  border: none !important;
}

a.disabled {
  color: $grey-disabled !important;
  cursor: default !important;
}

a.disabled:hover {
  color: $grey-disabled !important;
  cursor: default !important;
}

button.disabled {
  background: $grey-disabled !important;
  cursor: default !important;
}

button.disabled:hover {
  background: $grey-disabled !important;
  border: 0px solid $grey-disabled !important;
  color: $white-color !important;
}

.pe-none {
  pointer-events: none;
}

.cursor-na {
  cursor: not-allowed;
}

.notif-dot {
    text-align: center;
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #f0a500;
    right: 20px;
    color: #fff;
    font-size: 10px;
    border: 1px solid #fff;
    line-height: 18px;
}
.btn-arrow{
    background: transparent;
    // height: 50px;
    margin: auto;
    border: none;
    font-size: 30px;
    padding: 0;
    color: $primary-color;
}