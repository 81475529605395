@import "../../theme.scss";

body {
    background-color: #f7f7f7 !important;
}

.card {
    background: $white-color;
    box-shadow: 4px 8px 24px rgba(18, 18, 18, 0.05);
    border-radius: 30px !important;
    border: none !important;
}

.relative img {
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
