@import "../../../theme.scss";

.forum-card {
    background-color: $white-color;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.05);
    .forum-title {
        h6 {
            font-size: 1.25rem;
        }
    }
    .avainfo {
        display: flex;
        flex: 0 1;
        justify-content: space-between;
        align-items: center;
        .avaname {
            font-weight: 600;
        }
        .avadate {
            font-size: 14px;
            opacity: 0.5;
        }
        .category-col {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
            align-self: flex-end;
            width: 70%;
            .category {
                font-size: 0.75rem;
                padding: 5px 10px;
                margin: 4px 2px;
                cursor: pointer;
                border-radius: 16px;
                span {
                    text-align: center;
                }
            }
        }
    }
    .forum-body {
        padding: 0px;
    }
}
.detail-card {
    background-color: $white-color;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.05);
    .comment {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .detail-top {
        display: flex;
        align-items: center;
        gap: 0 10px;
        padding: 10px 30px 0 30px !important;
        img.rounded-img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }
        .detail-inside {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            * {
                padding: 0;
            }
            span {
                opacity: 0.4;
                font-size: 0.75rem;
            }
            .name {
                opacity: 1;
                font-size: 0.85rem;
                font-weight: 600;
            }
            .answer {
                padding: 0.35rem 1.15rem;
                border-radius: 3px;
                font-size: 0.75rem;
                font-weight: 600;
                cursor: pointer;
                border: none;
                background: $primary-color !important;
                color: #fff;
                &:hover {
                    border: 1px solid $primary-color;
                    background: #fff !important;
                    color: $primary-color;
                }
            }
        }
    }
    .detail-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        padding: 10px 30px;
        .btn {
            font-size: 0.75rem;
        }
    }
    .detail-body {
        display: flex;
        align-items: center;
        margin: 10px 0;
        gap: 0 10px;
        .indicator {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 10%;
            font-size: 1.25rem;
            font-weight: 600;
            cursor: pointer;
            .clicked {
                opacity: 0.2;
            }
        }
        .content {
            flex: 90%;
            .text {
            }
            .category {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 5px 5px;
                span {
                    opacity: 0.6;
                    border: 1px solid $font-black-color;
                    padding: 5px;
                    font-size: 0.75rem;
                    border-radius: 3px;
                }
            }
            .comment-section {
                padding: 10px 0;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                .comment-form {
                    width: 100%;
                    * {
                        margin-bottom: 10px;
                    }
                    label {
                        font-weight: 700;
                        margin: 0;
                    }
                    input {
                        background: #ececec;
                    }
                    textarea {
                        background: #ececec;
                    }
                }
            }
        }
    }
    .detail-bottom {
        display: flex;
        justify-content: flex-end;
        background: rgba(234, 240, 255, 0.55);
        align-items: center;
        padding: 10px 30px !important;
        .like-btn {
            font-size: 0.75rem;
            cursor: pointer;
        }
    }
}

.category-block {
    margin-top: 10px;
    font-weight: 600;
    .category-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0 5px;
        margin-top: 10px;
    }
}
.category-list {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    cursor: pointer;
    .category-body {
        display: flex;
        align-items: center;
        align-self: flex-start;
        padding: 0.25rem 1.15rem;
        border-radius: 10px;
        .dot {
            height: 10px;
            width: 10px;
            margin-right: 10px;
            border-radius: 50%;
        }
        a {
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
    &.true .category-body {
        background: $primary-color !important;
        color: $white-color !important;
        .dot {
            background-color: $white-color !important;
        }
    }
}
.top-block {
    font-weight: 600;
    .top-item {
        color: $font-black-color;
        font-weight: 500;
        margin: 10px 0;
        padding: 10px 0;
        border-bottom: 1px solid $light-grey-color;
        .title {
            font-size: 1rem;
        }
        .response {
            opacity: 0.5;
            font-size: 0.75rem;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}
.forum-create {
    label {
        font-weight: 700;
    }
    input {
        background-color: #ececec;
    }
    textarea {
        background-color: #ececec;
        min-height: calc(10.5em + 0.175rem + 5px) !important;
    }
    .forn-control {
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .form-control:focus {
        border: 3px solid $secondary-color;
        box-shadow: none;
    }
    .tag-wrapper {
        background-color: $secondary-color;
        padding: 5px;
        color: #fff;
        margin-right: 10px;
        button {
            background-color: $secondary-color;
            border: none;
            color: #fff;
        }
    }
    .ReactTags__tagInput input {
        border: 1px solid #ececec;
        padding: 10px;
        width: 100%;
        outline: none;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        &:focus {
            border: 3px solid $secondary-color !important;
        }
    }
}

.forum-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: justify;
    -webkit-line-clamp: 4;
    & p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-align: justify;
        -webkit-line-clamp: 4;
    }
    & div {
        display: none;
    }
}
