.font-size30 {
    font-size: 30px !important;
}

.font-size35r {
    font-size: 3.5rem !important;
}

.font-size25r {
    font-size: 2.5rem !important;
}

.font-size15r {
    font-size: 1.75rem !important;
}

.font-size20 {
    font-size: 20px !important;
}

.font-size12 {
    font-size: 12px !important;
}

.letter-spacing02r {
    letter-spacing: 0.2rem !important;
}

.line-height35r {
    line-height: 3.5rem !important;
}

.font-weight400 {
    font-weight: 400;
}

@media only screen and (max-width: 768px) {
    .font-size35r {
        font-size: 1.5rem !important;
        &.mobile {
            font-size: 3.5rem !important;
        }
    }

    .line-height35r {
        line-height: 1.8rem !important;
    }

    .font-size25r {
        font-size: 1.5rem !important;
    }

    .font-size15r {
        font-size: 1rem !important;
    }
}
