.margin10 {
    margin: 10px;
}

.margin30 {
    margin: 30px;
}

.margin-tb30 {
    margin: 30px 0;
}

.margin-p75 {
    margin: 0.75rem;
}

.margin-t0 {
    margin-top: 0px !important;
}

.margin-b0 {
    margin-bottom: 0px !important;
}

.margin-l0 {
    margin-left: 0px !important;
}

.margin-r0 {
    margin-right: 0px !important;
}

.margin-tb10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-lf10 {
    margin-left: 10px;
    margin-right: 10px;
}

.margin-r10 {
    margin-right: 10px;
}

.margin-l10 {
    margin-left: 10px;
}

.margin-t10 {
    margin-top: 10px;
}

.margin-b10 {
    margin-bottom: 10px;
}

.margin-b20 {
    margin-bottom: 20px !important;
}

.margin-b1r {
    margin-bottom: 1rem;
}

.margin-t30 {
    margin-top: 30px;
}

.margin-b30 {
    margin-bottom: 30px;
}

.margin-l30 {
    margin-left: 30px;
}

.margin-r30 {
    margin-right: 30px;
}

.margin-t50 {
    margin-top: 50px;
}

.margin-b50 {
    margin-bottom: 50px;
}

.margin-l50 {
    margin-left: 50px;
}

.margin-r50 {
    margin-right: 50px;
}

.margin-t81 {
    margin-top: 81px;
}

.margin-lr30 {
    margin: 0 30px;
}

.margin-tm5 {
    margin-top: -5px;
}

.top30p {
    top: 30% !important;
}

.top40p {
    top: 40% !important;
}

@media only screen and (max-width: 768px) {
    .mbl-margin0 {
        margin: 0px !important;
    }
    .margin-l10 {
        margin-left: 0px !important;
    }
}
