.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}
.gap{
    gap: 10px 0;
}
.display-flex {
    display: flex;
    &.overflow-hidden {
        overflow: hidden;
    }
    &.row {
        flex-direction: row;
    }
    &.space-between {
        justify-content: space-between;
    }
    & > .flex-item1 {
        flex: 1;
    }
    & > .flex-item2 {
        flex: 2;
    }
    &.center-horizontal {
        justify-content: center;
    }
    &.center-vertical {
        align-items: center;
    }
    & .flex1 {
        flex: 1;
    }
    & .flex2 {
        flex: 2;
    }
    & .flex3 {
        flex: 3;
    }
}

.overlay {
    content: "";
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.5)
    );
}

.visibility-hidden {
    visibility: hidden;
}

.u-role {
    margin-right: 10px;
    font-size: 0.75rem;
    padding: 0.15rem 0.75rem;
    border-radius: 3px;
    font-weight: 600;
    text-transform: capitalize;
    &.trainer {
        background-color: rgba(213, 145, 111, 0.1);
        border: 1px solid rgba(213, 145, 111, 1);
        color: rgba(213, 145, 111, 1);
    }
    &.member {
        background-color: rgba(139, 177, 189, 0.1);
        border: 1px solid rgba(139, 177, 189, 1);
        color: rgba(139, 177, 189, 1);
    }
    &.admin {
        background-color: rgba(163, 13, 13, 0.1);
        border: 1px solid rgb(255, 58, 58);
        color: rgba(163, 13, 13, 0.8);
    }
}
.u-status {
    font-size: 0.75rem;
    padding: 0.15rem 0.75rem;
    border-radius: 3px;
    font-weight: 600;
    text-transform: capitalize;
    &.active {
        background-color: rgba(43, 185, 0, 0.1);
        border: 1px solid rgb(43, 185, 0);
        color: rgba(43, 185, 0, 1);
    }
    &.not-active {
        background-color: rgba(255, 81, 81, 0.1);
        border: 1px solid rgb(255, 81, 81);
        color: rgba(255, 81, 81, 1);
    }
    &.publish {
        background-color: rgba(43, 185, 0, 0.1);
        border: 1px solid rgb(40, 41, 39);
        color: rgba(43, 185, 0, 1);
    }
    &.pending {
        background-color: rgba(255, 81, 81, 0.1);
        border: 1px solid rgb(255, 81, 81);
        color: rgba(255, 81, 81, 1);
    }
}

.cursor {
    &.pointer {
        cursor: pointer;
    }
}

hr {
    width: 10%;
    padding: 5px;
    background: $primary-color !important;
    opacity: 1 !important;
    margin: 0;
    &.tiny {
        padding: 2px !important;
    }
    @media only screen and (max-width: 750px) {
        width: 20%;
    }
}
