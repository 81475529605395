@import "../../../theme.scss";

.title {
    display: flex;
    gap: 0 10px;
    span {
        margin-right: 10px;
        font-size: 0.75rem;
        padding: 0.15rem 0.75rem;
        border-radius: 3px;
        font-weight: 600;
    }
    .label2 {
        background-color: rgba(213, 145, 111, 0.1);
        border: 1px solid rgba(213, 145, 111, 1);
        color: rgba(213, 145, 111, 1);
    }
    .label3 {
        background-color: rgba(139, 177, 189, 0.1);
        border: 1px solid rgba(139, 177, 189, 1);
        color: rgba(139, 177, 189, 1);
    }
    .label1 {
        background-color: rgba(173, 139, 189, 0.1);
        border: 1px solid rgba(173, 139, 189, 1);
        color: rgba(173, 139, 189, 1);
    }
}
.profile-content {
    @media only screen and (max-width: 750px) {
        text-align: center;
    }
}

// new

.profile {
    & .p-header {
        background-color: $white-color;
        width: 100%;
        height: auto;
        border-radius: 30px;
        overflow: hidden;
        & .top-header {
            & img {
                width: 50%;
                height: 300px;
                object-fit: cover;
                border-radius: 1rem;
            }
        }
        & .bottom-header {
            position: relative;
            & .bh-left {
                position: relative;
                flex: auto;
                & img {
                    position: absolute;
                    top: -100px;
                }
            }
            & .bh-right {
                & a {
                    float: right;
                }
            }
            & .btn-edit-profile {
                float: right;
            }
        }
    }
    & .p-body {
        & .card {
            width: 100%;
            height: 100%;
        }
    }
    & .p-detail {
        & > div {
            text-align: left;
            @media only screen and (max-width: 768px) {
                margin-top: 20px;
                text-align: center;
            }
        }
    }
    p {
        opacity: 0.6;
        font-size: 0.75rem;
    }
    .card-course,
    .card-forum {
        a {
            opacity: 0.8;
            font-size: 0.75rem;
            font-weight: 600;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0.25rem 0.5rem;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            &:hover {
                border: 1px solid $primary-color;
                background-color: $primary-color;
                color: #fff !important;
            }
        }
    }
    .learn-card {
        background: #f3f6f9;
    }
    .list-group-item {
        border: none;
        padding: 5px;
        &:nth-child(odd) {
            background: rgba(162, 204, 236, 0.15);
        }
    }
    .button-group {
        .primary {
            background-color: $primary-color !important;
            &:hover {
                background-color: $light-grey-color !important;
            }
        }
    }
}
