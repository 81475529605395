.padding0 {
    padding: 0 !important;
}

.padding5 {
    padding: 5px !important;
}

.padding10 {
    padding: 10px !important;
}

.padding-tb5 {
    padding: 5px 0 !important;
}

.padding-lr5 {
    padding: 0 5px !important;
}

.padding10 {
    padding: 10px !important;
}

.padding-tb10 {
    padding: 10px 0 !important;
}

.padding-lr10 {
    padding: 0 10px !important;
}

.padding30 {
    padding: 30px !important;
}

.padding-tb30 {
    padding: 30px 0 !important;
}

.padding-lr30 {
    padding: 0 30px !important;
}

.padding-t30 {
    padding-top: 30px !important;
}

.padding-b30 {
    padding-bottom: 30px !important;
}

.padding50 {
    padding: 50px !important;
}

.padding-tb50 {
    padding: 50px 0 !important;
}

.padding-lr50 {
    padding: 0 50px !important;
}

@media only screen and (max-width: 768px) {
    .padding-tb30 {
        padding: 15px 0 !important;
    }

    .padding30 {
        padding: 0 !important;
    }
}
