.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-justify {
    text-align: justify;
}

.text-bold600 {
    font-weight: 600;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-label {
    font-size: 0.5rem;
    color: $grey-color;
}

.text-label12 {
    font-size: 12px;
    color: $font-black-color;
    opacity: 0.7;
}

.timeline-text-red-color {
    color: $timeline-red-color;
}

.timeline-text-orange-color {
    color: $timeline-orange-color;
}

.timeline-text-green-color {
    color: $timeline-green-color;
}

.timeline-text-blue-color {
    color: $timeline-blue-color;
}

.timeline-text-purple-color {
    color: $timeline-purple-color;
}

.timeline-text-dark-blue-color {
    color: $timeline-dark-blue-color;
}

.text-elipsis-single {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-elipsis-multi {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: justify;
    &.line8 {
        -webkit-line-clamp: 8;
    }
    &.line4 {
        -webkit-line-clamp: 4;
    }
    &.text-c {
        text-align: center;
    }
}

.text-alert {
    color: $red-alert !important;
    font-style: italic;
}

@media only screen and (max-width: 768px) {
    .text-right {
        text-align: left;
    }

    .text-elipsis-multi {
        &.line8 {
            -webkit-line-clamp: 8;
            margin-bottom: 20px;
        }
    }
}
