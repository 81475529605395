@import "../../theme.scss";

.about {
  text-align: center;
  .header {
    h1 {
      font-weight: 700;
    }
    p {
      width: 50%;
      margin: 10px auto;
    }
    .abouts-img {
      position: relative;
      #left {
        position: absolute;
        background: $primary-color;
        width: 300px;
        height: 180px;
        top: 0;
        left: 0;
        z-index: -1;
      }
      #right {
        position: absolute;
        background: $brown-color;
        width: 300px;
        height: 180px;
        bottom: 0;
        right: 0;
        z-index: -1;
      }
      #center {
        width: 100%;
        padding: 20px;
        height: 400px;
        object-fit: cover;
        border-radius: 20px;
        filter: drop-shadow(4px 8px 24px rgba(18, 18, 18, 0.05));
      }
    }

    @media only screen and (max-width: 750px) {
      p {
        width: 80%;
      }
      img {
        height: 150px;
      }
    }
  }
}
.about-section-1 {
  padding: 30px 0;
}
.about-section-2 {
  margin: 50px 0;
  min-height: 400px;
  .content {
    background: rgba(92, 48, 42, 0.1);
    padding: 50px;
    border-radius: 5px;
    .stack-top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .row {
    margin: 50px 0;
  }
  .item {
    text-align: left !important;
    * {
      padding: 0;
    }
    .item-head {
      display: flex;
      align-items: flex-end;
      gap: 0 5px;
      .bg-icon {
        font-size: 2rem;
        padding: 5px 15px;
        font-family: $font-second;
      }
      span {
        letter-spacing: 0.15rem;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
    p {
      margin: 10px 0;
    }
  }
}
.content {
  h2 {
    font-weight: 600;
  }
  h3 {
    font-weight: 600;
    font-size: 18px;
    opacity: 0.5;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
  }
  p {
    opacity: 0.8;
  }
}
.about-section-3 {
  text-align: center;
  margin: 30px 0;
  .item {
    margin: 30px 0;
    p {
      width: 70%;
      margin: auto;
    }
  }
}
.about-section-4 {
  text-align: center;
  background: url("../../assets/images/section4.png") no-repeat;
  background-size: cover;
  padding: 50px 0;
  h2 {
    font-weight: 700;
  }
  .item {
    margin: 30px 0;
    text-align: center;
    * {
      padding: 0;
    }
    h4 {
      font-size: 1.75rem;
      font-weight: 600;
    }
    p {
      width: 50%;
      margin: auto;
    }

    @media screen and (max-width: 750px) {
      h4 {
        font-size: 1.5rem;
        font-weight: 600;
      }
      p {
        width: 100%;
      }
    }
  }
  .grid-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;

    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    .grid {
      position: relative;
      max-width: 250px;
      height: 400px;
      margin-right: 10px;
      overflow: hidden;
      flex: 0 0 auto;

      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      .grid-image {
        display: block;
        height: 100%;
        overflow: hidden;
        position: relative;
        img {
          width: 100% !important;
          height: 100%;
          object-fit: cover;

          -webkit-transition: all 0.25s ease-in-out;
          -moz-transition: all 0.25s ease-in-out;
          -ms-transition: all 0.25s ease-in-out;
          -o-transition: all 0.25s ease-in-out;
          transition: all 0.25s ease-in-out;
        }
      }
      .grid-overlay {
        content: "";
        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        background-image: linear-gradient(
          to bottom,
          rgba(black, 0.1),
          rgba(black, 0.6)
        );

        .grid-text {
          position: absolute;
          bottom: 0;
          padding: 1.5em;
          z-index: 3;
          p {
            color: #f8f8f8;
            text-align: start;
            display: inline-block;
            font-size: 1.25em;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 0.75em;
            text-decoration: none;

            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
            &:hover {
              color: rgba(white, 0.7);
              text-decoration: none;
            }
          }
        }
      }
      &:hover {
        img {
          -webkit-transform: scale(1.2);
          -moz-transform: scale(1.2);
          -ms-transform: scale(1.2);
          -o-transform: scale(1.2);
          transform: scale(1.2);
        }
      }
    }

    @media screen and (max-width: 750px) {
      justify-content: start;
    }
  }
}
.banner-img {
  img{
    height: 350px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    &:hover{
      filter: drop-shadow(4px 8px 24px rgba(18, 18, 18, 0.05));
    }
  }
}