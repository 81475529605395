.right {
  float: right !important;
  justify-content: flex-end !important;
}

.none {
  float: none !important;
}

.transform-center {
  transform: translate(-50%, -50%);
}

.relative {
  position: relative;
}

.p-absolute {
  position: absolute;
  &.left0 {
    left: 0;
  }
  &.left10p {
    left: 10px;
  }
  &.left20p {
    left: 20px;
  }
  &.left50p {
    left: 50px;
  }
  &.right0 {
    right: 0;
  }
  &.right20p {
    right: 20px;
  }
  &.top0 {
    top: 0;
  }
  &.top-m100 {
    top: -100px;
  }
  &.bottom0 {
    bottom: 0;
  }
  &.left50 {
    left: 50%;
  }
  &.top50 {
    top: 50%;
  }
  &.transform-t50 {
    transform: translateY(-50%);
  }
  &.transform-l50 {
    transform: translateX(-50%);
  }
  &.transform-center {
    transform: translate(-50%, -50%);
  }
}

.z-index3 {
  z-index: 3 !important;
}

.z-index2 {
  z-index: 2 !important;
}

.z-index1 {
  z-index: 1 !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-none {
  object-fit: none;
}

.object-position-center {
  object-position: center;
}

@media only screen and (max-width: 768px) {
  .p-absolute {
    position: relative;
    &.mobile {
      position: absolute;
    }
  }
}
