@import "../../../theme.scss";

.user-body{
    .u-name{
        font-size: 1rem;
        font-weight: 600;
        &:hover{
            color: $secondary-color;
        }
    }
    .list-group-item {
        border: none;
        padding: 5px;
        &:nth-child(odd) {
            background: rgba(162, 204, 236, 0.15);
        }
    }
}